import React from 'react';
import { useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import './CSS/Home.css';

import Menu from "../components/Menu"
import ImageWrapper from "../components/ImageWrapper";

function Minecraft(){
    return (
        <div>
            <header>
                <title>Unity</title>
            </header>

            <Menu />

            <div className="home-background home-body">
                <p>Similar to Discord bots, Minecraft server plugins leverage my programming skillset. I have made a few 
                    well received plugins as well. I am most known for my TimTheEnchanter redux, Whisp's TimTheEnchanter 
                    (which I am rewriting), and Universes, my multi-world management plguin. Both have received several thousand  
                    downloads. Universes earned me a small partnership with Apex Minecraft Hosting.
                </p>
            </div>
        </div>
    )
}

export default Minecraft;