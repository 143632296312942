import React from 'react';
import { useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import './CSS/Home.css';

import Menu from "../components/Menu"
import ImageWrapper from "../components/ImageWrapper";

function Blender(){
    return (
        <div>
            <header>
                <title>Blender</title>
            </header>

            <Menu />

            <div className="home-background home-body">
                <p>Blender is where I make props and modify models. 
                </p>
            </div>
        </div>
    )
}

export default Blender;