import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import './App.css';
import Home from "./views/home";
import About from "./views/about";
import Unity from "./views/unity"
import Blender from "./views/blender"
import SubstancePainter from './views/substance_painter';
import DiscordBots from './views/discord_bots';
import Minecraft from './views/minecraft';
import Access from './views/access';

function App() {
  return (
      <div>
          <Router>
                <Routes>
                   <Route index element={<Home />} />
                   <Route path="/home" element={<Home /> } />
                   <Route path="/about" element={<About />} />
                   <Route path="/unity" element={<Unity />} />
                   <Route path="/substance painter" element={<SubstancePainter />} />
                   <Route path="/blender" element={<Blender />} />
                   <Route path="/discord bots" element={<DiscordBots />} />
                   <Route path="/minecraft plugins" element={<Minecraft />} />
                   <Route path="/vrc/leap/access" element={<Access />} />
                </Routes>
          </Router>
      </div>
  );
}

export default App;
