import React from 'react';
import { useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import './CSS/Home.css';

import Menu from "../components/Menu"
import ImageWrapper from "../components/ImageWrapper";

function DiscordBots(){
    return (
        <div>
            <header>
                <title>Discord Bots</title>
            </header>

            <Menu />

            <div className="home-background home-body">
                <p>Discord bots reach into the core of my programming skillset. I have made bots from a Tupperbox clone to 
                    World of Warcraft guild management utility and club DJ management bots. I accept many kinds of programming 
                    related commissions, however they lean toward the pricier side of my offerings. Reach out to me at [] for 
                    more information.
                </p>
            </div>
        </div>
    )
}

export default DiscordBots;