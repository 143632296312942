import React from 'react';
import { useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import './CSS/Home.css';

import Menu from "../components/Menu"
import ImageWrapper from "../components/ImageWrapper";

function Home() {
    return (
        <div className="home">
            <header className="home-header">
                <title>Home</title>
            </header>
            <div className="home-background home-bg-img">
                
            </div>
            <Menu />
            <div className="home-background2">
            </div>
            <ImageWrapper>
                <div><img src={require("../assets/club_bar.png")} /></div>
                <div><img src={require("../assets/grabbable_torch_anim.png")} /></div>
                <div><img src={require("../assets/glow_crystal_sconce.png")} /></div>
                <div><img src={require("../assets/otter_design.png")} /></div>
            </ImageWrapper>
            <div className="home-background home-body">
                <h1>Hello!</h1>
                <p>I'm Whisp Reedwell, a software developer and 3D artist based on the East Coast, USA. I have over three thousand hours of experience combined
                    in Unity, Blender, and Substance painter, and over 6 years of experience programming in Java, C#, and Web. My focus is on VRChat avatars,
                    VRChat Worlds, Discord bots, and Minecraft server plugins.</p>
            </div>
        </div>
    );
}

export default Home;