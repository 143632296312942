import React from 'react';
import { useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import './CSS/Home.css';

import Menu from "../components/Menu"
import ImageWrapper from "../components/ImageWrapper";

function SubstancePainter(){
    return (
        <div>
            <header>
                <title>Substance Painter</title>
            </header>

            <Menu />

            <div className="home-background home-body">
                <p>Substance Painter is where all the 3D assets are textured.
                </p>
            </div>
        </div>
    )
}

export default SubstancePainter;