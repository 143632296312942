import React from 'react';
import { useRef, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import './CSS/Home.css';

import Menu from "../components/Menu"
import ImageWrapper from "../components/ImageWrapper";

function Unity(){
    return (
        <div>
            <header>
                <title>Unity</title>
            </header>

            <Menu />

            <div className="home-background home-body">
                <p>Unity is where the bulk of my post-modeling VRChat world work happens, and where shader work, physics, and
                    interactive systems are done for VRChat avatars. Have a look at my work below!
                </p>
            </div>
        </div>
    )
}

export default Unity;