import React from "react";
import "./CSS/ImageWrapper.css";

type Props = {
    children: JSX.Element[]
}

const ImageWrapper = ({children} : Props) => {
    return (
        <div className="img-wrapper">
            {children}
        </div>
    );
}

export default ImageWrapper;